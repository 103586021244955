<template>
  <div>
    Success
  </div>
</template>

<script>
  export default {
    data() {
      return {
        data: {}
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>